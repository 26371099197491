import React, {useEffect, useState} from 'react';
import { useMediaQuery } from 'react-responsive';

import {Link} from 'react-router-dom';

import Box from '@/lib/components/Box';
import Footer from '@/lib/components/Footer';
import Navbar from '@/lib/components/Navbar';
import PageContent from '@/lib/components/PageContent';
import Text from '@/lib/components/Text';

import Constants from '@/lib/constants';
import Theme from '@/lib/styling/theme';

const Styles = {
    contentPane: {
        marginTop: 24,
        backgroundColor: 'white',
        paddingTop: 32,
        paddingBottom: 32,
        paddingLeft: 32,
        paddingRight: 32,
        borderRadius: 4,
        border: '1px solid rgb(236, 239, 241)',
    },
};

export default function Help() {
    return (
        <Box style={{flex: 1, backgroundColor: 'rgb(246, 249, 252)'}}>
            <PageContent style={{backgroundColor: 'white', borderBottom: '1px solid rgb(236, 239, 241)'}}>
                <Navbar color={Theme.colorPrimary} showCreate={false} />
            </PageContent>
            <PageContent>
                <Box style={{paddingTop: 48, paddingBottom: 52}}>
                    <Box><Text textStyle="chungus" bold>🔎 Help</Text></Box>
                    <Box style={Styles.contentPane}>
                        <Text style={{lineHeight: 1.7}}>The information below is provided for educational purposes only, and should not be construed as financial advice. Always thoroughly conduct your own research when making financial decisions.</Text>
                        <Text textStyle="title" bold style={{marginTop: 24}}>What's a cryptocurrency?</Text>
                        <Text style={{lineHeight: 1.7, marginTop: 8}}><a href="https://en.wikipedia.org/wiki/Cryptocurrency" style={{fontWeight: 'bold'}} target="_blank">Wikipedia</a> has a great introduction to cryptocurrencies. Essentially, it's like digital internet money!</Text>

                        <Text textStyle="title" bold style={{marginTop: 24}}>What are the private keys and public addresses on my card?</Text>
                        <Text style={{lineHeight: 1.7, marginTop: 8}}>Think of the public address and private key as like a username and password for a cryptocurrency wallet. The address lets people (like the awesome person who sent you the card!) send cryptocurrency to your wallet, and the private key is like the password that you can use to control the wallet and withdraw the funds in it.</Text>

                        <Text textStyle="title" bold style={{marginTop: 24}}>How do I withdraw the cryptocurrency on my card?</Text>
                        <Text style={{lineHeight: 1.7, marginTop: 8}}>To withdraw the funds on the card, you should use a trustworthy wallet app or website that allows you to import the wallet from the private key or <em>sweep</em> the funds in the wallet.</Text>

                        <Text textStyle="title" bold style={{marginTop: 24}}>Can {Constants.APP_NAME} recommend a wallet app to withdraw cryptocurrency from the card?</Text>
                        <Text style={{lineHeight: 1.7, marginTop: 8}}>{Constants.APP_NAME} is unable to recommend third party wallet software. Try Googling for a wallet with private key importing or sweep functionality, or ask the person who sent you the card for advice.</Text>

                        <Text textStyle="title" bold style={{marginTop: 24}}>Does the card expire?</Text>
                        <Text style={{lineHeight: 1.7, marginTop: 8}}>Cryptocurrency does not expire (as long as the network powering it is running). However, we <strong>strongly</strong> recommend you transfer the cryptocurrency off the card wallets into your own wallets for security purposes.</Text>

                        <Text textStyle="title" bold style={{marginTop: 24}}>How do I convert the cryptocurrency to cash?</Text>
                        <Text style={{lineHeight: 1.7, marginTop: 8}}>In general, you can withdraw the cryptocurrency to a cryptocurrency exchange to sell it into fiat money. {Constants.APP_NAME} is unable to recommend third party exchanges.</Text>

                        <Text textStyle="title" bold style={{marginTop: 24}}>The QR codes on my card aren't scanning.</Text>
                        <Text style={{lineHeight: 1.7, marginTop: 8}}>It's possible the camera is picking up the other QR codes on the card around the one you are trying to scan. Try zooming in on the QR code if possible, or covering the other QR codes. If that still doesn't work, try manually entering in the private key or address.</Text>
                    </Box>
                </Box>
            </PageContent>
            <Box style={{flex: 1}} />
            <PageContent style={{marginTop: 16, backgroundColor: '#f5f5f5', borderTop: '1px solid rgb(236, 239, 241)'}}>
                <Footer />
            </PageContent>
        </Box>
    );
}
