import mergeStyles from '@/lib/styling/mergeStyles';

const Styles = {
    root: {
        border: '1px solid #777',
        borderRadius: 4,
        padding: 8
    },
};

export default function TextArea({style, ...restProps}) {
    return (
        <textarea style={mergeStyles(Styles.root, style)} {...restProps} />
    );
}
