export default function(wallets) {
    const outputWallets = [];
    const erc20s = [];
    let ethWallet = null;
    for (const wallet of wallets) {
        if (wallet.asset !== 'erc20') {
            const walletCopy = {...wallet};
            outputWallets.push(walletCopy);
            if (wallet.asset === 'eth') {
                ethWallet = walletCopy;
            }
        } else {
            erc20s.push(wallet);
        }
    }

    if (ethWallet) {
        ethWallet.tokens = erc20s;
    }
    return outputWallets;
}
