import Box from '@/lib/components/Box';
import Button from '@/lib/components/Button';

import mergeStyles from '@/lib/styling/mergeStyles';

function ColorOption({colorOption, onSelect, selected=false}) {
    return (
        <Box style={mergeStyles({width: 28, height: 28, padding: 8, cursor: 'pointer', borderRadius: 4, marginRight: 8}, selected && {backgroundColor: '#eaeaea'})} onClick={onSelect}>
            {/* components are set up like this to allow fall-back if the browser doesn't support linear-gradient*/}
            <Box style={{flex: 1, border: '1px solid #eee', borderRadius: 4, overflow: 'hidden', backgroundColor: colorOption.backgroundColor}}>
                <Box style={{flex: 1, background: `linear-gradient(135deg, ${colorOption.backgroundColor}, ${colorOption.backgroundColor} 50%, ${colorOption.foregroundColor} 50%, ${colorOption.foregroundColor})`}} />
            </Box>
        </Box>
    );
}

export default function ColorThemePicker({colorOptions, selectedIndex, onSelectionChanged}) {
    return (
        <Box style={{overflowX: 'hidden'}} direction="row">
            {colorOptions.map((option, index) => <ColorOption key={index} colorOption={option} selected={index === selectedIndex} onSelect={() => onSelectionChanged(index)} />)}
        </Box>
    );
}
