import React, {useEffect, useRef, useState} from 'react';

import {Prompt} from 'react-router-dom';

import {useMediaQuery} from 'react-responsive';

import { useBeforeunload } from 'react-beforeunload';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

import AssetDescriptions from '@/lib/asset/AssetDescriptions';

import Card from '@/lib/components/Card';
import Box from '@/lib/components/Box';
import Button from '@/lib/components/Button';
import Footer from '@/lib/components/Footer';
import Navbar from '@/lib/components/Navbar';
import PageContent from '@/lib/components/PageContent';
import Text from '@/lib/components/Text';
import TextInput from '@/lib/components/TextInput';
import TextArea from '@/lib/components/TextArea';

import ColorThemePicker from '@/lib/create/ColorThemePicker';
import DepositStepContent from '@/lib/create/DepositStep';
import GenerateLink from '@/lib/create/GenerateLink';

import GiftCard from '@/lib/GiftCard';
import CardHeader from '@/lib/GiftCard/Header';

import mergeStyles from '@/lib/styling/mergeStyles';

import Constants from '@/lib/constants';
import Theme from '@/lib/styling/theme';

import {IoIosLink} from 'react-icons/io';
import {IoInformationCircle, IoPrint, IoRefresh, IoWarning} from 'react-icons/io5';

const Styles = {
    workArea: {
        marginTop: 24,
    },
    prevButton: {
        background: 'none',
        borderTopLeftRadius: 24,
        borderBottomLeftRadius: 24,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        paddingLeft: 20,
        paddingRight: 20,
    },
    nextButton: {
        backgroundColor: Theme.colorPrimary,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        borderTopRightRadius: 24,
        borderBottomRightRadius: 24,
        paddingLeft: 20,
        paddingRight: 20,
    },
    stepPane: {
        flex: 0.25,
        paddingRight: 20,
        paddingTop: 18,
    },
    stepPaneStep: {
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 24,
        paddingRight: 24,
        opacity: 0.54,
    },
    stepPaneStepSelected: {
        backgroundColor: Theme.colorPrimary,
        color: 'white',
        borderRadius: 4,
        opacity: 1,
    },
    contentPane: {
        backgroundColor: 'white',
        paddingTop: 32,
        paddingBottom: 32,
        paddingLeft: 32,
        paddingRight: 32,
        borderRadius: 4,
        border: '1px solid rgb(236, 239, 241)',
    },
};

const STEP_DETAILS = 'STEP_DETAILS';
const STEP_DEPOSIT = 'STEP_DEPOSIT';
const STEP_DONE = 'STEP_DONE';

function StepPane({currentStep}) {
    const isDesktop = useMediaQuery({
        minWidth: 600,
    });

    return (
        <Box style={mergeStyles(Styles.stepPane, !isDesktop && {paddingRight: 0, marginBottom: 16})}>
            <Box style={mergeStyles(Styles.stepPaneStep, currentStep === STEP_DETAILS && Styles.stepPaneStepSelected)}>
                <Text style={{cursor: 'default'}} bold>1. Details</Text>
            </Box>
            <Box style={mergeStyles(Styles.stepPaneStep, currentStep === STEP_DEPOSIT && Styles.stepPaneStepSelected)}>
                <Text style={{cursor: 'default'}} bold>2. Add Wallets</Text>
            </Box>
            <Box style={mergeStyles(Styles.stepPaneStep, currentStep === STEP_DONE && Styles.stepPaneStepSelected)}>
                <Text style={{cursor: 'default'}} bold>3. Done!</Text>
            </Box>
            {/*<Text style={{marginLeft: 16, marginTop: 8}}><span style={{fontStyle: 'italic'}}>That's it?</span>{' '}<span style={{fontWeight: 'bold'}}>Yup.</span></Text>*/}
        </Box>
    );
}

const COLOR_COMBINATIONS = [
    {
        backgroundColor: Theme.colorPrimary,
        foregroundColor: '#ffcb17',
        textColor: '#fff',
    },
    {
        backgroundColor: Theme.colorPrimary,
        foregroundColor: '#fff',
        textColor: '#fff',
    },
    {
        backgroundColor: Theme.colorAccent,
        foregroundColor: '#fff',
        textColor: '#fff',
    },
    {
        backgroundColor: '#111',
        foregroundColor: '#fff',
        textColor: '#fff',
    },
    {
        backgroundColor: '#fefefe',
        foregroundColor: '#333',
        textColor: '#333',
    },
];

function DetailsStep({onNext, to, message, colorCombinationIdx, setTo, setMessage, setColorCombinationIdx}) {
    const isDesktop = useMediaQuery({
        minWidth: 600,
    });
    const cardWidth = isDesktop ? 400 : 280;

    const colorCombination = COLOR_COMBINATIONS[colorCombinationIdx];

    return (
        <Box style={Styles.contentPane}>
            <Box style={{alignItems: 'center'}}>
                <Card style={{width: cardWidth, borderRadius: 4}}>
                    <CardHeader
                        width={cardWidth}
                        backgroundColor={colorCombination.backgroundColor}
                        foregroundColor={colorCombination.foregroundColor}
                        textColor={colorCombination.textColor}
                        to={to}
                        message={message}
                    />
                </Card>
                <Text style={{fontWeight: 'normal', marginTop: 8}} bold secondary>Preview</Text>
            </Box>
            <Text style={{fontWeight: 'normal', marginTop: 32, marginBottom: 4}} bold secondary>Pick a theme</Text>
            <ColorThemePicker colorOptions={COLOR_COMBINATIONS} selectedIndex={colorCombinationIdx} onSelectionChanged={(idx) => setColorCombinationIdx(idx)}/>
            <Text style={{fontWeight: 'normal', marginTop: 32, marginBottom: 4}} bold secondary>Who's it for? (optional)</Text>
            <TextInput style={{fontSize: 20}} placeholder="Satoshi Nakamoto" value={to} onChange={(ev) => setTo(ev.target.value)} />
            <Text style={{fontWeight: 'normal', marginTop: 32, marginBottom: 4}} bold secondary>Add a message (optional)</Text>
            <TextArea style={{fontSize: 20}} placeholder="Hey, have you heard about this thing called Bitcoin?" rows={10} value={message} onChange={(ev) => setMessage(ev.target.value)} />
        </Box>
    );
}

function DepositStep({wallets, onWalletAdded, onRemoveWallet, showDisclaimer, onDisclaimerAgree}) {
    return (
        <Box style={Styles.contentPane}>
            <DepositStepContent wallets={wallets} onWalletAdded={onWalletAdded} onRemoveWallet={onRemoveWallet} showDisclaimer={showDisclaimer} onDisclaimerAgree={onDisclaimerAgree} />
        </Box>
    );
}

function DoneStep({to, message, colorCombinationIdx, wallets}) {
    const isDesktop = useMediaQuery({
        minWidth: 600,
    });
    const cardWidth = isDesktop ? 600 : 280;
    const colorCombination = COLOR_COMBINATIONS[colorCombinationIdx];

    const cardRef = useRef(null);

    const [generateLinkOpen, setGenerateLinkOpen] = useState(false);

    const [walletBalances, setWalletBalances] = useState({});
    const [assetPrices, setAssetPrices] = useState({});

    const fetchBalances = () => {
        for (const wallet of wallets) {
            const {fetchBalance} = AssetDescriptions[wallet.asset] || {};
            fetchBalance && fetchBalance(wallet.address).then(balance => setWalletBalances(prevBalances => ({...prevBalances, [wallet.asset]: balance})));
        }
    };

    const fetchPrices = () => {
        for (const wallet of wallets) {
            const {fetchPrice} = AssetDescriptions[wallet.asset] || {};
            fetchPrice && fetchPrice().then(price => setAssetPrices(prevPrices => ({...prevPrices, [wallet.asset]: price})));
        }
    };

    const handleRefreshBalances = () => {
        fetchBalances();
        fetchPrices();
    };

    useEffect(fetchBalances, [wallets]);
    useEffect(fetchPrices, [wallets]);

    useEffect(() => {
        const assets = wallets.map((wallet) => wallet.asset);
        const assets_counts = {};
        for (const asset of assets) {
            assets_counts[asset] = 1;
        }
        window.gtag && window.gtag('event', 'create_card', {
            color_combination: colorCombinationIdx,
            assets_counts,
        });
    }, []);

    return (
        <Box style={Styles.contentPane}>
            <Box style={{alignItems: 'center'}}>
                <Text textStyle="headline" bold>That's all! Here's your card.</Text>
                <Text textStyle="caption" secondary style={{marginTop: 4}}>You can press the "Back" button below to edit details and wallets.</Text>
                <Box direction="row" style={{width: cardWidth - 2 * 8, border: '1px solid #ffcc00', backgroundColor: 'rgba(255, 204, 0, 0.2)', borderRadius: 4, padding: 8, marginTop: 24, marginBottom: 24}}>
                    <Box style={{width: 20}}>
                        <IoWarning color="#ffcc00" size={20} />
                    </Box>
                    <Text textStyle="caption" secondary bold style={{marginLeft: 4}}>When you leave this page, Cryptomas won't remember this card or its wallets. Make sure you download the card or save the shareable link before leaving.</Text>
                </Box>
                <Card style={{width: cardWidth, borderRadius: 4}}>
                    <GiftCard
                        width={cardWidth}
                        backgroundColor={colorCombination.backgroundColor}
                        foregroundColor={colorCombination.foregroundColor}
                        textColor={colorCombination.textColor}
                        to={to}
                        message={message}
                        wallets={wallets}
                        walletBalances={walletBalances}
                        assetPrices={assetPrices}
                    />
                </Card>
                {/* Used for printing the card*/}
                <Box style={{display: 'none'}}>
                    <div style={{width: Math.max(800 - (wallets.length * 102), 280)}} ref={cardRef}>
                        <GiftCard
                            width={Math.max(800 - (wallets.length * 102), 280)}
                            backgroundColor={colorCombination.backgroundColor}
                            foregroundColor={colorCombination.foregroundColor}
                            textColor={colorCombination.textColor}
                            to={to}
                            message={message}
                            wallets={wallets}
                            walletBalances={walletBalances}
                            assetPrices={assetPrices}
                        />
                    </div>
                </Box>
                <Box direction="row" style={{width: cardWidth - 2 * 8, border: '1px solid #777', backgroundColor: 'rgba(128, 128, 128, 0.1)', borderRadius: 4, padding: 8, marginTop: 32, marginBottom: 16}}>
                    <Box style={{width: 20}}>
                        <IoInformationCircle color="#777" size={20} />
                    </Box>
                    <Text textStyle="caption" secondary style={{marginLeft: 4}}>To download a PDF, click Print and select "Save as PDF" as the destination. If the card doesn't fit in one page, you can adjust the scale in the Print dialog. Make sure the QR codes are scannable.</Text>
                </Box>
                <Box>
                    <ReactToPrint content={() => cardRef.current}>
                        <PrintContextConsumer>
                            {({handlePrint}) => (
                                <Button style={{backgroundColor: Theme.colorAccent, borderRadius: 4, paddingLeft: 16, paddingRight: 16}} onClick={handlePrint}>
                                    <Box direction="row" style={{alignItems: 'center'}}>
                                        <Box style={{flex: 1, alignItems: 'flex-end', marginRight: 4}}>
                                            <IoPrint color="white" size={20} />
                                        </Box>
                                        <Text style={{color: 'white'}} bold>Print</Text>
                                        <Box style={{flex: 1}} />
                                    </Box>
                                </Button>
                            )}
                        </PrintContextConsumer>
                    </ReactToPrint>
                    <Button onClick={() => setGenerateLinkOpen(true)} style={{marginTop: 4, backgroundColor: Theme.colorAccent, borderRadius: 4, paddingLeft: 16, paddingRight: 16}}>
                        <Box direction="row" style={{alignItems: 'center'}}>
                            <Box style={{flex: 1, alignItems: 'flex-end', marginRight: 4}}>
                                <IoIosLink color="white" size={20} />
                            </Box>
                            <Text style={{color: 'white'}} bold>Get shareable link</Text>
                            <Box style={{flex: 1}} />
                        </Box>
                    </Button>
                    <Button onClick={handleRefreshBalances} style={{marginTop: 4, backgroundColor: '#ccc', border: '1px solid #eee', borderRadius: 4, paddingLeft: 16, paddingRight: 16}}>
                        <Box direction="row" style={{alignItems: 'center'}}>
                            <Box style={{flex: 1, alignItems: 'flex-end', marginRight: 4}}>
                                <IoRefresh color="black" size={20} />
                            </Box>
                            <Text bold>Refresh balances</Text>
                            <Box style={{flex: 1}} />
                        </Box>
                    </Button>
                    {generateLinkOpen && (
                        <GenerateLink isOpen onRequestClose={() => setGenerateLinkOpen(false)} card={{
                            details: {
                                to,
                                message,
                                colorCombination,
                            },
                            wallets,
                        }}/>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

function WorkArea() {
    const isDesktop = useMediaQuery({
        minWidth: 600,
    });

    const [currentStep, setCurrentStep] = useState(STEP_DETAILS);
    const [colorCombinationIdx, setColorCombinationIdx] = useState(0);

    const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

    const [to, setTo] = useState('');
    const [message, setMessage] = useState('');
    const [wallets, setWallets] = useState([]);

    useBeforeunload(() => 'Cryptomas doesn\'t save your card or wallet keys. If you leave this page, Cryptomas won\'t remember your generated wallets, so make sure you record all wallet keys and addresses. Are you sure you want to leave?');

    const handleWalletAdded = (wallet) => {
        setWallets(prevWallets => [...prevWallets, wallet]);
    };

    const handleRemoveWallet = (asset) => {
        setWallets(prevWallets => {
            const walletsCopy = [...prevWallets];
            let output = walletsCopy;
            if (asset.startsWith('erc20:')) {
                const address = asset.substring(6);
                output = walletsCopy.filter((wallet) => wallet.asset !== 'erc20' || wallet.erc20.tokenAddress !== address);
            } else {
                output = walletsCopy.filter((wallet) => wallet.asset !== asset);
                if (asset === 'eth') {
                    output = output.filter((wallet) => wallet.asset !== 'erc20');
                }
            }
            return output;
        });
    };

    const renderCurrentStep = () => {
        switch (currentStep) {
            case STEP_DETAILS:
                return <DetailsStep
                    onNext={() => setCurrentStep(STEP_DEPOSIT)}
                    to={to}
                    setTo={setTo}
                    message={message}
                    setMessage={setMessage}
                    colorCombinationIdx={colorCombinationIdx}
                    setColorCombinationIdx={setColorCombinationIdx}/>;
            case STEP_DEPOSIT:
                return <DepositStep wallets={wallets} onWalletAdded={handleWalletAdded} onRemoveWallet={handleRemoveWallet} showDisclaimer={!disclaimerAccepted} onDisclaimerAgree={() => setDisclaimerAccepted(true)}/>;
            case STEP_DONE:
                return <DoneStep wallets={wallets} to={to} message={message} colorCombinationIdx={colorCombinationIdx} />
        }
        return null;
    };

    const handleNextClicked = () => {
        switch (currentStep) {
            case STEP_DETAILS:
                setCurrentStep(STEP_DEPOSIT);
                return;
            case STEP_DEPOSIT:
                setCurrentStep(STEP_DONE);
                return;
        }
    };

    const handleBackClicked = () => {
        switch (currentStep) {
            case STEP_DEPOSIT:
                setCurrentStep(STEP_DETAILS);
                return;
            case STEP_DONE:
                setCurrentStep(STEP_DEPOSIT);
                return;
        }
    };

    return (
        <Box direction={isDesktop ? 'row' : 'column'} style={mergeStyles(Styles.workArea, !isDesktop && {marginTop: 12})}>
            <Prompt message="Cryptomas doesn't save your card or wallet keys. If you leave this page, Cryptomas won't remember your card or its wallets, so make sure you record all wallet keys and addresses. Are you sure you want to leave?" />
            <StepPane currentStep={currentStep} />
            <Box style={{flex: 1}}>
                {renderCurrentStep()}
                <Box direction="row" style={{marginTop: 24, justifyContent: 'flex-end'}}>
                    {currentStep !== STEP_DETAILS && (
                        <Button style={Styles.prevButton} onClick={handleBackClicked}>
                            <Text style={{color: Theme.colorPrimary}} bold>Back</Text>
                        </Button>
                    )}
                    {currentStep !== STEP_DONE && !(currentStep == STEP_DEPOSIT && !wallets.length) && (
                        <Button style={Styles.nextButton} onClick={handleNextClicked}>
                            <Text style={{color: 'white'}} bold>Next</Text>
                        </Button>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

export default function CreateFlow() {
    const isDesktop = useMediaQuery({
        minWidth: 600,
    });

    return (
        <Box style={{flex: 1, backgroundColor: 'rgb(246, 249, 252)'}}>
            <PageContent style={{backgroundColor: 'white', borderBottom: '1px solid rgb(236, 239, 241)'}}>
                <Navbar color={Theme.colorPrimary} showCreate={false} />
            </PageContent>
            <PageContent>
                <Box style={{paddingTop: 48, paddingBottom: 52}}>
                    <Box><Text textStyle="chungus" style={!isDesktop && {fontSize: 32, textAlign: 'center'}} bold>✨ Create a card</Text></Box>
                    <WorkArea />
                </Box>
            </PageContent>
            <Box style={{flex: 1}} />
            <PageContent style={{marginTop: 16, backgroundColor: '#f5f5f5', borderTop: '1px solid rgb(236, 239, 241)'}}>
                <Footer />
            </PageContent>
        </Box>
    );
}
