import React, {useEffect, useState} from 'react';
import { useMediaQuery } from 'react-responsive';

import {Link} from 'react-router-dom';

import Box from '@/lib/components/Box';
import Footer from '@/lib/components/Footer';
import Navbar from '@/lib/components/Navbar';
import PageContent from '@/lib/components/PageContent';
import Text from '@/lib/components/Text';

import Constants from '@/lib/constants';
import Theme from '@/lib/styling/theme';

const Styles = {
    contentPane: {
        marginTop: 24,
        backgroundColor: 'white',
        paddingTop: 32,
        paddingBottom: 32,
        paddingLeft: 32,
        paddingRight: 32,
        borderRadius: 4,
        border: '1px solid rgb(236, 239, 241)',
    },
};

export default function Legal() {
    return (
        <Box style={{flex: 1, backgroundColor: 'rgb(246, 249, 252)'}}>
            <PageContent style={{backgroundColor: 'white', borderBottom: '1px solid rgb(236, 239, 241)'}}>
                <Navbar color={Theme.colorPrimary} showCreate={false} />
            </PageContent>
            <PageContent>
                <Box style={{paddingTop: 48, paddingBottom: 52}}>
                    <Box><Text textStyle="chungus" bold>⚖️ Legal</Text></Box>
                    <Box style={Styles.contentPane}>
                        <Text id="terms" style={{marginBottom: 12}} textStyle="headline" bold>Terms of use</Text>
                        <Text style={{lineHeight: 1.7}}>{Constants.APP_NAME} is free software. The software generates keys for on-chain wallets and does not hold your funds or store your keys for you. <span style={{fontWeight: 'bold', opacity: 0.8}}>It is your responsibility to keep your keys safe.</span></Text>
                        <Text style={{lineHeight: 1.7, marginTop: 12}}>
                            Although it has been tested, we cannot make any guarantees or provide any warranty regarding the reliability or security of the software or the third party software libraries it depends on. No part of the software has been formally audited. {Constants.APP_NAME} and its developers cannot be held liable or responsible for lost or stolen funds and are not obligated to assist in the recovery of any funds. <strong>Use Cryptomas at your own risk.</strong>
                        </Text>
                        <Text style={{lineHeight: 1.7, marginTop: 12}}>
                            This software is provided "as is", without warranty of any kind, express or implied, including but not limited to the warranties of merchantability, fitness for a particular purpose and noninfringement. In no event shall the authors or copyright holders be liable for any claim, damages or other liability, whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software or the use or other dealings in the software.
                        </Text>

                        <Text id="privacy" style={{marginBottom: 12, marginTop: 32}} textStyle="headline" bold>Privacy policy</Text>
                        <Text style={{lineHeight: 1.7}}>In general, {Constants.APP_NAME} does not collect personally identifiable information; the core functionality of the product happens locally in your browser. However, we do use Google Analytics to anonymously track user behavior and collect statistics for product improvement purposes. Refer to the Google privacy policy <a href="https://policies.google.com/privacy/google-partners?hl=en-US" target="_blank" style={{fontWeight: 'bold'}}>here</a>.</Text>
                    </Box>
                </Box>
            </PageContent>
            <Box style={{flex: 1}} />
            <PageContent style={{marginTop: 16, backgroundColor: '#f5f5f5', borderTop: '1px solid rgb(236, 239, 241)'}}>
                <Footer />
            </PageContent>
        </Box>
    );
}
