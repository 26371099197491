import React, {useEffect, useState} from 'react';
import { useMediaQuery } from 'react-responsive';

import {Link} from 'react-router-dom';

import Box from '@/lib/components/Box';
import Card from '@/lib/components/Card';
import Footer from '@/lib/components/Footer';
import Navbar from '@/lib/components/Navbar';
import PageContent from '@/lib/components/PageContent';
import SupportedCryptocurrencies from '@/lib/frontpage/SupportedCryptocurrencies';
import Text from '@/lib/components/Text';

import Constants from '@/lib/constants';
import Theme from '@/lib/styling/theme';

import GiftCard from '@/lib/GiftCard';
import AssetDescriptions from "@/lib/asset/AssetDescriptions";

import SlapMeme from './slap_meme.jpg';

const Styles = {
    contentPane: {
        marginTop: 24,
        backgroundColor: 'white',
        paddingTop: 32,
        paddingBottom: 32,
        paddingLeft: 32,
        paddingRight: 32,
        borderRadius: 4,
        border: '1px solid rgb(236, 239, 241)',
    },
};

export default function About() {
    return (
        <Box style={{flex: 1, backgroundColor: 'rgb(246, 249, 252)'}}>
            <PageContent style={{backgroundColor: 'white', borderBottom: '1px solid rgb(236, 239, 241)'}}>
                <Navbar color={Theme.colorPrimary} showCreate={false} />
            </PageContent>
            <PageContent>
                <Box style={{paddingTop: 48, paddingBottom: 52}}>
                    <Box><Text textStyle="chungus" bold>❓ About</Text></Box>
                    <Box style={Styles.contentPane}>
                        <Text textStyle="title" bold>What is this?</Text>
                        <Text style={{lineHeight: 1.7, marginTop: 8}}>Great question! {Constants.APP_NAME} is a web app that makes it easy to generate holiday-themed cryptocurrency gift cards that you can send to your friends and family. These gift cards are basically just nice-looking multiple-currency paper wallets.</Text>

                        <Text textStyle="title" bold style={{marginTop: 24}}>What cryptocurrencies does it support?</Text>
                        <Text style={{lineHeight: 1.7, marginTop: 8}}>{Constants.APP_NAME} currently supports Bitcoin, Ethereum, Litecoin, Dogecoin, Algorand, and any arbitrary ERC-20 token. If there's something you'd like me to add, <a href="https://github.com/tonypeng/cryptomas-public/issues" style={{fontWeight: 'bold'}} target="_blank">create an issue on Github</a>!</Text>

                        <Text textStyle="title" bold style={{marginTop: 24}}>Does {Constants.APP_NAME} store my cryptocurrency for me?</Text>
                        <Text style={{lineHeight: 1.7, marginTop: 8}}><strong>No!</strong> {Constants.APP_NAME} simply <em>generates</em> keys that correspond to wallets on the blockchain. We don't hold any of your funds, ever. The wallet generation process happens client-side, in your browser. Your private keys, by design, never leave your computer.</Text>


                        <Text textStyle="title" bold style={{marginTop: 24}}>Has {Constants.APP_NAME} been audited? Is it secure?</Text>
                        <Text style={{lineHeight: 1.7, marginTop: 8}}><strong>No.</strong> {Constants.APP_NAME} has not been formally audited in any way. I made {Constants.APP_NAME} over a weekend <strong>because I was bored</strong> and I wanted to make crypto gift cards for my friends. It uses a bunch of third party libraries that are not guaranteed to be safe or vulnerability-proof. And while I believe the chance is very low and I will personally be using it, there's also always the chance the wallet generation code could be buggy and lead to loss of funds. <strong>Use {Constants.APP_NAME} at your own risk. I am not liable for any issues that arise from using this software.</strong> See the <Link to="/legal" style={{fontWeight: 'bold'}}>Legal</Link> page.</Text>

                        <Text textStyle="title" bold style={{marginTop: 24}}>Is there a non-themed version of this? I want to use this as paper wallet.</Text>
                        <Text style={{lineHeight: 1.7, marginTop: 8}}>Once again, this software is <strong>not</strong> audited and I <strong>strongly</strong> discourage depositing any significant amount of funds in wallets created by this software.</Text>

                        <Text textStyle="title" bold style={{marginTop: 24}}>Is it open-source?</Text>
                        <Text style={{lineHeight: 1.7, marginTop: 8}}>{Constants.APP_NAME} is <em>partially</em> open source. Given that the functionality could easily be modified to phish users and steal wallet keys and funds, I have decided that, for the time being, it would not be a good idea to release the full code in the wild. However, parts of the code that I consider critical to security are open-sourced for public auditing.</Text>

                        <Text textStyle="title" bold style={{marginTop: 24}}>How can I verify {Constants.APP_NAME} isn't saving my keys somewhere?</Text>
                        <Text style={{lineHeight: 1.7, marginTop: 8}}>You can look at the Network requests in the developer tools of any modern browser and verify that your keys are not being sent off anywhere. The web app should <em>theoretically</em> work offline as well once you've loaded the app, although I haven't tried this in practice.</Text>

                        <Text textStyle="title" bold style={{marginTop: 24}}>If {Constants.APP_NAME} isn't storing keys, then how am I able to share links to cards?</Text>
                        <Text style={{lineHeight: 1.7, marginTop: 8}}>All the information (private keys, card message, etc.) needed to render a card is stored in the URL link. The data is encoded as UTF-8, then serialized to JSON, and finally base64-encoded.</Text>


                        <Text textStyle="title" bold style={{marginTop: 24}}>How does {Constants.APP_NAME} generate private keys?</Text>
                        <Text style={{lineHeight: 1.7, marginTop: 8}}>
                            <a href="https://github.com/tonypeng/cryptomas-public" style={{fontWeight: 'bold'}} target="_blank">Take a look at the code.</a> {Constants.APP_NAME} uses a bunch of third-party libraries to generate wallets. It is my understanding that they all use <Text style={{backgroundColor: 'rgba(20, 20, 20, 0.2)', border: '1px solid #777', padding: '4px 6px', borderRadius: 4}} code>window.crypto</Text> to securely generate random numbers. The npm packages used are provided below:<br />
                            <ul>
                                <li>
                                    For <strong>Bitcoin</strong>, <strong>Litecoin</strong>, and <strong>Doge</strong>: <Text style={{backgroundColor: 'rgba(20, 20, 20, 0.2)', border: '1px solid #777', padding: '4px 6px', borderRadius: 4}} code>coinkey</Text> <Text style={{backgroundColor: 'rgba(20, 20, 20, 0.2)', border: '1px solid #777', padding: '4px 6px', borderRadius: 4}} code>coininfo</Text> <Text style={{backgroundColor: 'rgba(20, 20, 20, 0.2)', border: '1px solid #777', padding: '4px 6px', borderRadius: 4}} code>secure-random</Text>
                                </li>
                                <li style={{marginTop: 8}}>
                                    For <strong>Ethereum</strong>: <Text style={{backgroundColor: 'rgba(20, 20, 20, 0.2)', border: '1px solid #777', padding: '4px 6px', borderRadius: 4}} code>ethereumjs-wallet</Text>
                                </li>
                                <li style={{marginTop: 8}}>
                                    For <strong>Algorand</strong>: <Text style={{backgroundColor: 'rgba(20, 20, 20, 0.2)', border: '1px solid #777', padding: '4px 6px', borderRadius: 4}} code>algosdk</Text>
                                </li>
                            </ul>
                        </Text>

                        <Text textStyle="title" bold style={{marginTop: 24}}>Is there a token for this? How do I farm thi-</Text>
                        <img style={{marginTop: 8, maxWidth: '100%'}} src={SlapMeme} />
                    </Box>
                </Box>
            </PageContent>
            <Box style={{flex: 1}} />
            <PageContent style={{marginTop: 16, backgroundColor: '#f5f5f5', borderTop: '1px solid rgb(236, 239, 241)'}}>
                <Footer />
            </PageContent>
        </Box>
    );
}
