import { useMediaQuery } from 'react-responsive';

import {Link} from 'react-router-dom';

import Box from '@/lib/components/Box';
import Text from '@/lib/components/Text';
import Constants from '@/lib/constants';

import Theme from '@/lib/styling/theme';
import mergeStyles from "@/lib/styling/mergeStyles";

import './Footer.css';

const Styles = {
    root: {
        alignItems: 'center',
        paddingTop: 16,
        paddingBottom: 16,
    },
};

export default function Footer() {
    const isDesktop = useMediaQuery({
        minWidth: 832,
    });

    return (
        <Box style={Styles.root} direction={isDesktop ? "row" : "column"}>
            <Box style={!isDesktop && {alignItems: 'center'}}>
                <Box direction="row" style={{alignItems: 'center', marginBottom: 4}}>
                    <span style={{fontSize: 20}}>🎄</span>
                    <Text textStyle="subhead" style={{color: Theme.colorPrimary}} bold>{Constants.APP_NAME}</Text>
                    {isDesktop && (
                        <>
                            <Box style={{width: 24}} />
                            <Link to="/legal#terms" target="_blank"><Text style={mergeStyles({color: 'black'}, !isDesktop && {fontSize: 12})} className="Footer--link" bold>Terms</Text></Link>
                            <Box style={{width: 12}} />
                            <Link to="/legal#privacy" target="_blank"><Text style={mergeStyles({color: 'black'}, !isDesktop && {fontSize: 12})} className="Footer--link" bold>Privacy</Text></Link>
                            <Box style={{width: 12}} />
                            <a href="https://github.com/tonypeng/cryptomas-public" target="_blank"><Text style={mergeStyles({color: 'black'}, !isDesktop && {fontSize: 12})} className="Footer--link" bold>Github</Text></a>
                            <Box style={{width: 12}} />
                            <Link to="/about" target="_blank"><Text style={mergeStyles({color: 'black'}, !isDesktop && {fontSize: 12})} className="Footer--link" bold>About</Text></Link>
                            <Box style={{width: 12}} />
                            <Link to="/help" target="_blank"><Text style={mergeStyles({color: 'black'}, !isDesktop && {fontSize: 12})} className="Footer--link" bold>Help</Text></Link>
                            <Box style={{width: 12}} />
                            <Link to="/credits" target="_blank"><Text style={mergeStyles({color: 'black'}, !isDesktop && {fontSize: 12})} className="Footer--link" bold>Credits</Text></Link>
                        </>
                    )}
                </Box>
                <Text textStyle="caption" style={{color: '#777', marginTop: -6}} bold>Cryptomas is provided as-is with no warranty.</Text>
            </Box>
            <Box style={isDesktop ? {flex: 1} : {height: 12}} />
            {!isDesktop && (
                <>
                    <Link to="/legal#terms" target="_blank"><Text style={mergeStyles({color: 'black'}, !isDesktop && {fontSize: 12})} className="Footer--link" bold>Terms</Text></Link>
                    <Link to="/legal#privacy" target="_blank"><Text style={mergeStyles({color: 'black'}, !isDesktop && {fontSize: 12})} className="Footer--link" bold>Privacy</Text></Link>
                    <a href="https://github.com/tonypeng/cryptomas-public" target="_blank"><Text style={mergeStyles({color: 'black'}, !isDesktop && {fontSize: 12})} className="Footer--link" bold>Github</Text></a>
                    <Link to="/about" target="_blank"><Text style={mergeStyles({color: 'black'}, !isDesktop && {fontSize: 12})} className="Footer--link" bold>About</Text></Link>
                    <Link to="/help" target="_blank"><Text style={mergeStyles({color: 'black'}, !isDesktop && {fontSize: 12})} className="Footer--link" bold>Help</Text></Link>
                    <Link to="/credits" target="_blank"><Text style={mergeStyles({color: 'black'}, !isDesktop && {fontSize: 12})} className="Footer--link" bold>Credits</Text></Link>
                    <Box style={isDesktop ? {flex: 1} : {height: 12}} />
                </>
            )}
            <Box style={{alignItems: 'flex-end'}}>
                <Text textStyle="caption" style={{color: '#777'}} bold>Made with 📈 by <a href="https://twitter.com/iamtonypeng" target="_blank">@iamtonypeng</a>. &copy; 2020</Text>
                <Text textStyle="caption" style={{color: '#777'}} bold>Feeling generous? <a href="https://www.binance.charity/crypto-against-covid" target="_blank">Donate crypto to COVID relief.</a></Text>
                <Text style={{fontSize: 8, color: '#777'}} bold>Not an endorsement. {Constants.APP_NAME} is not affiliated with Binance.</Text>
            </Box>
        </Box>
    );
}
