import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import { createBrowserHistory } from 'history';

import Modal from 'react-modal';

import FrontPage from '@/lib/frontpage';
import CreateFlow from '@/lib/create/CreateFlow';
import ViewCard from '@/lib/view_card';
import About from '@/lib/about';
import Help from '@/lib/help';
import Legal from '@/lib/legal';
import Credits from '@/lib/credits';

import './App.css';

Modal.setAppElement('#root');

const history = createBrowserHistory();
history.listen((location) => {
    window.ga && window.ga('set', 'page', location.pathname + location.search);
    window.ga && window.ga('send', 'pageview');
});

function App() {
  return (
    <div className="App">
        <Router history={history}>
              <Switch>
                  <Route path="/" exact>
                      <FrontPage />
                  </Route>
                  <Route path="/create" exact>
                      <CreateFlow />
                  </Route>
                  <Route path="/card" exact>
                      <ViewCard />
                  </Route>
                  <Route path="/about" exact>
                      <About />
                  </Route>
                  <Route path="/help" exact>
                      <Help />
                  </Route>
                  <Route path="/legal" exact>
                      <Legal />
                  </Route>
                  <Route path="/credits" exact>
                      <Credits />
                  </Route>
              </Switch>
        </Router>
    </div>
  );
}

export default App;
