import mergeStyles from '@/lib/styling/mergeStyles';

export default function Button({style, ...restProps}) {
    return (
        <button style={mergeStyles(
            {
                border: 'none',
                padding: 8,
                outline: 'none',
            },
            !restProps.disabled && {
                cursor: 'pointer',
            },
            style,
        )} {...restProps} />
    );
}
