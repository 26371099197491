import { useMediaQuery } from 'react-responsive';

import {Link} from 'react-router-dom';

import Box from '@/lib/components/Box';
import Button from '@/lib/components/Button';
import Text from '@/lib/components/Text';

import Constants from '@/lib/constants';

import './Navbar.css';
import mergeStyles from "@/lib/styling/mergeStyles";

const Styles = {
    root: {
        alignItems: 'center',
        paddingTop: 16,
        paddingBottom: 16,
    },
};

export default function Navbar({color='black', showCreate=true}) {
    const isDesktop = useMediaQuery({
        minWidth: 600,
    });

    return (
        <Box style={Styles.root} direction="row">
            <Link to="/">
                <Box direction="row" style={{alignItems: 'center'}}>
                    <span style={{fontSize: isDesktop? 32 : 24}}>🎄</span>
                    <Text textStyle={isDesktop ? 'headline' : 'subhead'} bold style={{color, marginLeft: isDesktop ? 4 : 0}}>{Constants.APP_NAME}</Text>
                </Box>
            </Link>
            <Box style={{flex: 1}} />
            <Box direction="row" style={{alignItems: 'center'}}>
                <a href="https://github.com/tonypeng/cryptomas-public" target="_blank"><Text style={mergeStyles({color}, !isDesktop && {fontSize: 12})} className="Navbar--link" bold>Github</Text></a>
                <Box style={{width: isDesktop ? 20 : 8}} />
                <Link to="/about"><Text style={mergeStyles({color}, !isDesktop && {fontSize: 12})} className="Navbar--link" bold>About</Text></Link>
                <Box style={{width: isDesktop ? 20 : 8}} />
                <Link to="/help"><Text style={mergeStyles({color}, !isDesktop && {fontSize: 12})} className="Navbar--link" bold>Help</Text></Link>
                {showCreate && (
                    <>
                        <Box style={{width: isDesktop ? 20 : 8}} />
                        <Link to="/create" style={{paddingLeft: isDesktop ? 16 : 8, paddingRight: isDesktop ? 16 : 8, paddingTop: 4, paddingBottom: 4, backgroundColor: '#0cb04a', border: 'none', borderRadius: 999, marginTop: 2}}>
                            <Box style={{alignItems: 'center'}}>
                                <Text style={mergeStyles({color: 'white'}, !isDesktop && {fontSize: 12})} bold>Create</Text>
                            </Box>
                        </Link>
                    </>
                )}
            </Box>
        </Box>
    );
}
