import Box from '@/lib/components/Box';
import mergeStyles from '@/lib/styling/mergeStyles';

export default function PageContent({style, children}) {
    return (
        <Box style={mergeStyles({flexDirection: 'column', alignItems: 'center'}, style)}>
            <Box style={{flexDirection: 'column', maxWidth: 1200, width: '100%'}}>
                <Box style={{paddingLeft: 32, paddingRight: 32}}>
                    {children}
                </Box>
            </Box>
        </Box>
    );
}
