import Box from '@/lib/components/Box';
import mergeStyles from '@/lib/styling/mergeStyles';

const Styles = {
    cardContainer: {
        borderRadius: 4,
        boxShadow: '0px 3px 15px rgba(0,0,0,0.2)',
        backgroundColor: 'white',
        overflow: 'hidden',
    },
};

export default function Card({style, ...restProps}) {
    return (
        <Box style={mergeStyles(Styles.cardContainer, style)} {...restProps} />
    );
}
