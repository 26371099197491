import { useMediaQuery } from 'react-responsive';

import Ticker from 'react-ticker';

import Box from '@/lib/components/Box';
import Text from '@/lib/components/Text';

import BitcoinLogo from './bitcoin_logo.png';
import EthereumLogo from './ethereum_logo.png';
import LitecoinLogo from './litecoin_logo.png';
import AlgorandLogo from './algorand_logo.png';
import DogecoinLogo from './dogecoin_logo.png';
import ChainlinkLogo from "@/lib/frontpage/SupportedCryptocurrencies/chainlink_logo.png";
import TetherLogo from "@/lib/frontpage/SupportedCryptocurrencies/tether_logo.png";
import UsdcLogo from "@/lib/frontpage/SupportedCryptocurrencies/usdc_logo.png";
import UniswapLogo from "@/lib/frontpage/SupportedCryptocurrencies/uniswap_logo.png";
import YfiLogo from "@/lib/frontpage/SupportedCryptocurrencies/yfi_logo.png";
import AmpleforthLogo from "@/lib/frontpage/SupportedCryptocurrencies/ampleforth_logo.png";

const Styles = {
    root: {
        backgroundColor: '#f3f3f3',
        paddingTop: 16,
        paddingBottom: 28,
    },
};

const renderCryptos = [
    () => <img src={BitcoinLogo} style={{width: 48}} />,
    () => <img src={EthereumLogo} style={{width: 48}} />,
    () => <img src={LitecoinLogo} style={{width: 48}} />,
    () => <img src={AlgorandLogo} style={{width: 48}} />,
    () => <img src={DogecoinLogo} style={{width: 48}} />,
    () => <img src={ChainlinkLogo} style={{width: 48}} />,
    () => <img src={UniswapLogo} style={{width: 48}} />,
    () => <img src={YfiLogo} style={{width: 48}} />,
    () => <img src={AmpleforthLogo} style={{width: 48}} />,
    () => <img src={TetherLogo} style={{width: 48}} />,
    () => <img src={UsdcLogo} style={{width: 48}} />,
    () => (
        <Box style={{justifyContent: 'center', height: 48}}>
            <Text style={{fontSize: 24}} bold>ERC-20</Text>
        </Box>
    ),
];

export default function SupportedCryptocurrencies() {
    const showTicker = useMediaQuery({
        maxWidth: 1000,
    });

    return (
        <Box style={Styles.root} direction="column">
            <Text style={{textAlign: 'center', opacity: 0.54, marginBottom: 20}} bold>Supported Cryptocurrencies</Text>
            {showTicker ? (
                <Ticker height={48}>
                    {({ index }) => {
                        const renderItem = renderCryptos[index % renderCryptos.length];

                        return (
                            <Box style={{paddingLeft: 20, paddingRight: 20, height: 48}}>
                                {renderItem()}
                            </Box>
                        );
                    }}
                </Ticker>
            ) : (
                <Box style={{alignItems: 'center'}}>
                    <Box direction="row" style={{justifyContent: 'space-around', alignItems: 'center', marginTop: 16, maxWidth: 1000, width: '100%'}}>
                        {(renderCryptos).map(renderItem => renderItem())}
                    </Box>
                </Box>
            )}
        </Box>
    );
}
