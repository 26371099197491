import React, {useEffect, useState} from 'react';
import { useMediaQuery } from 'react-responsive';

import {Link} from 'react-router-dom';

import Box from '@/lib/components/Box';
import Card from '@/lib/components/Card';
import Footer from '@/lib/components/Footer';
import Navbar from '@/lib/components/Navbar';
import PageContent from '@/lib/components/PageContent';
import SupportedCryptocurrencies from '@/lib/frontpage/SupportedCryptocurrencies';
import Text from '@/lib/components/Text';

import Constants from '@/lib/constants';
import Theme from '@/lib/styling/theme';

import GiftCard from '@/lib/GiftCard';
import AssetDescriptions from "@/lib/asset/AssetDescriptions";

const Big = require('toformat')(require('big.js'));

const Styles = {
    howItWorksSection: {
        maxWidth: 760,
        marginTop: 40,
        borderRadius: 8,
    },
    howItWorksSectionIcon: {
        marginRight: 32,
    },
};

function HowItWorksSection({icon, title, description}) {
    return (
        <Box style={Styles.howItWorksSection} direction="row">
            <Box style={Styles.howItWorksSectionIcon}>{icon}</Box>
            <Box>
                <Text textStyle="headline" style={{color: Theme.colorPrimary}} bold>{title}</Text>
                <Text style={{lineHeight: 1.8, marginTop: 8}} secondary>{description}</Text>
            </Box>
        </Box>
    );
}

export default function FrontPage() {
    const [assetPrices, setAssetPrices] = useState({});

    const fetchPrices = () => {
        for (const asset of ['btc', 'eth', 'doge']) {
            const {fetchPrice} = AssetDescriptions[asset] || {};
            fetchPrice && fetchPrice().then(price => setAssetPrices(prevPrices => ({...prevPrices, [asset]: price})));
        }
    };

    useEffect(fetchPrices, []);

    const showHorizontal = useMediaQuery({
        minWidth: 760,
    });


    return (
        <Box style={{flex: 1}}>
            <PageContent style={{backgroundColor: Theme.colorPrimary}}>
                <Navbar color="white" />
                <Box style={{paddingTop: 48, paddingBottom: 52}}>
                    <Box style={{alignItems: 'center'}}>
                        <span style={{fontSize: 112}}>🎅</span>
                        <Text style={{fontSize: 48, textAlign: 'center', color: 'white'}} bold>This year, give the gift of crypto.</Text>
                        <Text style={{marginTop: 16, marginBottom: 24, textAlign: 'center', lineHeight: 1.8, color: 'white'}}>
                            Generate a paper wallet gift card with your favorite cryptocurrencies for your friends and family.<br />
                            It's simple, completely free, and takes as little as a minute.
                        </Text>
                        <Link to="/create" style={{paddingLeft: 32, paddingRight: 32, paddingTop: 12, paddingBottom: 12, backgroundColor: '#0cb04a', border: 'none', borderRadius: 999}}>
                            <Text textStyle="title" style={{color: 'white', opacity: 0.94}} bold>Get started</Text>
                        </Link>
                        {/*<Box style={{marginTop: 64}}>*/}
                        {/*<Text style={{fontSize: 32}} bold>How it works</Text>*/}
                        {/*</Box>*/}
                    </Box>
                </Box>
            </PageContent>
            <SupportedCryptocurrencies />
            <PageContent style={{marginTop: 48}}>
                <Text textStyle="headline" style={{textAlign: 'center'}} bold>How it works</Text>
                <Box style={{alignItems: 'center'}}>
                    <Box>
                        <HowItWorksSection
                            icon={<Text style={{fontSize: 28}}>🔐</Text>}
                            title="In-browser wallet generation"
                            description={`For each cryptocurrency you attach to your card, ${Constants.APP_NAME} locally generates a wallet on the corresponding blockchain. This process happens within your browser and your keys never leave your computer.`} />
                        <HowItWorksSection
                            icon={<Text style={{fontSize: 28}}>📥</Text>}
                            title="Deposit crypto"
                            description={`Send crypto to your generated wallets. Your wallets live directly on their respective blockchains—${Constants.APP_NAME} is non-custodial and never touches your crypto.`} />
                        <HowItWorksSection
                            icon={<Text style={{fontSize: 28}}>✉️</Text>}
                            title="Send it off!"
                            description={`Add an optional message, pick a theme, and download a PDF or copy a shareable link to send to that special someone.`} />
                    </Box>
                </Box>
                <Box style={{marginTop: 72, alignItems: 'center', marginBottom: 16}}>
                    <Box direction={showHorizontal ? "row" : "column"} style={{alignItems: 'center'}}>
                        <Card style={showHorizontal ? {width: 400, minWidth: 400} : {width: 300, minWidth: 300}}>
                            <GiftCard
                                width={showHorizontal ? 400 : 300}
                                to="Satoshi Nakamoto"
                                message="Hey, have you heard about this thing called Bitcoin?"
                                backgroundColor={Theme.colorPrimary}
                                foregroundColor="#fff"
                                textColor="#fff"
                                wallets={[
                                    {
                                        asset: 'btc',
                                        privateKey: 'bG9sIG5pY2UgdGhlcmUgYXJlIG5vIGJpdGNvcm5zIGhlcmU=',
                                        address: '14bBnn6Y5N6UDqUYp3orR7b52CP366ajBV',
                                        _qrPrivateKeyOverride: 'lol nice try no bitcorn here',
                                    },
                                    {
                                        asset: 'eth',
                                        privateKey: '0x3cd4c756a1de04dcd676251a2973a459199b3d083cd4c756a1de04dcd676',
                                        address: '0x3cd4c756a1de04dcd676251a2973a459199b3d08',
                                        _qrPrivateKeyOverride: 'nothing here either :^)',
                                    },
                                    {
                                        asset: 'doge',
                                        privateKey: 'aGFwcHkgaG9saWRheXMgc3RyYW5nZXIgOik=',
                                        address: 'DEzg3VzqF8ARxWd8Q3G1rsqJ7MkpUFtKJy',
                                        _qrPrivateKeyOverride: 'such cheer many happy holidays to you stranger',
                                    },
                                ]}
                                walletBalances={{btc: new Big(0.002), eth: new Big(0.1), doge: new Big(25000)}}
                                assetPrices={assetPrices} />
                        </Card>
                        <Box style={{marginLeft: showHorizontal && 72, alignItems: showHorizontal ? 'flex-start' : 'center', marginTop: !showHorizontal && 32}}>
                            <Text textStyle="chungus" style={{textAlign: !showHorizontal && 'center'}} bold>Spread holiday crypto cheer.</Text>
                            <Box direction="row">
                                <Link to="/create" style={{paddingLeft: 32, paddingRight: 32, paddingTop: 12, paddingBottom: 12, backgroundColor: '#0cb04a', border: 'none', borderRadius: 999, marginTop: 16}}>
                                    <Text textStyle="title" style={{color: 'white', opacity: 0.94}} bold>Make a card</Text>
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </PageContent>
            <Box style={{flex: 1}} />
            <PageContent style={{marginTop: 48, backgroundColor: '#f5f5f5'}}>
                <Footer />
            </PageContent>
        </Box>
    )
}
