import {useEffect, useState} from 'react';

import AssetDescriptions from '@/lib/asset/AssetDescriptions';
import makeAlgorandPassphraseQrString from '@/lib/asset/makeAlgorandPassphraseQrString';

import walletsListToStructured from '@/lib/asset/walletsListToStructured';

import Modal from 'react-modal';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import QRCode from 'qrcode.react';

import Box from '@/lib/components/Box';
import Button from '@/lib/components/Button';
import Text from '@/lib/components/Text';
import TextInput from '@/lib/components/TextInput';

import CreateWallet from '@/lib/create/CreateWallet';

import Constants from '@/lib/constants';
import Theme from '@/lib/styling/theme';
import mergeStyles from '@/lib/styling/mergeStyles';

import {IoMdAdd, IoMdTrash, IoIosClose} from 'react-icons/io';
import {IoInformationCircle, IoWarning} from 'react-icons/io5';
import {useMediaQuery} from "react-responsive";


const STATE_DISCLAIMER = 'STATE_DISCLAIMER';
const STATE_DEPOSIT = 'STATE_DEPOSIT';

function Disclaimer({onContinue}) {
    const [agreeChecked, setAgreeChecked] = useState(false);

    return (
        <Box style={{alignItems: 'center'}}>
            <Box style={{alignItems: 'center'}}>
                <Text bold style={{marginBottom: 24}}>Before continuing, please carefully read this disclaimer.</Text>
                <Text>{Constants.APP_NAME} is free software. The software generates keys for on-chain wallets and does not hold your funds or store your keys for you. <span style={{fontWeight: 'bold', opacity: 0.8}}>It is your responsibility to keep your keys safe.</span></Text>
                <Text style={{marginTop: 12}}>
                    Although it has been tested, we cannot make any guarantees or provide any warranty regarding the reliability or security of the software or the third party software libraries it depends on. No part of the software has been formally audited. {Constants.APP_NAME} and its developers cannot be held liable or responsible for lost or stolen funds and are not obligated to assist in the recovery of any funds. <strong>Use Cryptomas at your own risk.</strong>
                </Text>
                <Text style={{marginTop: 12}}>
                    This software is provided "as is", without warranty of any kind, express or implied, including but not limited to the warranties of merchantability, fitness for a particular purpose and noninfringement. In no event shall the authors or copyright holders be liable for any claim, damages or other liability, whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software or the use or other dealings in the software.
                </Text>
                <Box direction="row" style={{alignItems: 'center', marginTop: 24, marginBottom: 8}}>
                    <input value={agreeChecked} type="checkbox" id="agree_checkbox" onChange={(ev) => setAgreeChecked(ev.target.checked)} />
                    <label htmlFor="agree_checkbox"><Text style={{marginLeft: 4}}>I understand the above and agree to these terms.</Text></label>
                </Box>
                <Button
                    style={{borderRadius: 4, backgroundColor: agreeChecked ? Theme.colorAccent : '#999', paddingLeft: 12, paddingRight: 12}}
                    disabled={!agreeChecked}
                    onClick={() => onContinue()}>
                    <Text style={{color: 'white'}} bold>Continue</Text>
                </Button>
            </Box>
        </Box>
    );
}

const ConfirmDeleteModalStyle = {
    overlay: {
        zIndex: 10000,
        background: 'rgba(77, 0, 10, 0.2)',
    },
    content: {
        height: 176,
        width: 400,
        padding: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -200,
        marginLeft: -200,
        // borderRadius: 20,
        border: 'none',
    },
};
function ConfirmDeleteModal({asset, onRequestClose, onRemoveWallet}) {
    return (
        <Modal style={ConfirmDeleteModalStyle} onRequestClose={onRequestClose} isOpen>
            <Box style={{borderBottom: '1px solid #ddd', padding: 12, alignItems: 'center', flexDirection: 'row', justifyContent: 'center'}}>
                <Text textStyle="title" style={{opacity: 0.92, color: Theme.colorPrimary}}>Remove Wallet</Text>
            </Box>
            <Box style={{padding: 16}}>
                <Text>Are you sure you want to remove your {AssetDescriptions[asset].name} wallet? This action is irreversible.</Text>
            </Box>
            <Box direction="row" style={{borderTop: '1px solid #ddd', padding: 8, alignItems: 'center', justifyContent: 'flex-end'}}>
                <Button style={{backgroundColor: 'white', borderRadius: 4, marginRight: 8}} onClick={onRequestClose}>
                    <Text style={{color: '#222'}} bold>Cancel</Text>
                </Button>
                <Button style={{backgroundColor: Theme.colorPrimary, borderRadius: 4}} onClick={() => onRemoveWallet(asset)}>
                    <Text style={{color: 'white'}} bold>Remove</Text>
                </Button>
            </Box>
        </Modal>
    );
}

function ConfirmDeleteERC20Modal({erc20, onRequestClose, onRemoveWallet}) {
    return (
        <Modal style={ConfirmDeleteModalStyle} onRequestClose={onRequestClose} isOpen>
            <Box style={{borderBottom: '1px solid #ddd', padding: 12, alignItems: 'center', flexDirection: 'row', justifyContent: 'center'}}>
                <Text textStyle="title" style={{opacity: 0.92, color: Theme.colorPrimary}}>Remove Wallet</Text>
            </Box>
            <Box style={{padding: 16}}>
                <Text>Are you sure you want to remove your {erc20.tokenName} wallet? This action is irreversible.</Text>
            </Box>
            <Box direction="row" style={{borderTop: '1px solid #ddd', padding: 8, alignItems: 'center', justifyContent: 'flex-end'}}>
                <Button style={{backgroundColor: 'white', borderRadius: 4, marginRight: 8}} onClick={onRequestClose}>
                    <Text style={{color: '#222'}} bold>Cancel</Text>
                </Button>
                <Button style={{backgroundColor: Theme.colorPrimary, borderRadius: 4}} onClick={() => onRemoveWallet('erc20:' + erc20.tokenAddress)}>
                    <Text style={{color: 'white'}} bold>Remove</Text>
                </Button>
            </Box>
        </Modal>
    );
}

const ShowWalletDetailsModalStyle = {
    overlay : {
        position         : 'fixed',
        top              : 0,
        left             : 0,
        right            : 0,
        bottom           : 0,
        display          : 'flex',
        alignItems       : 'center',
        justifyContent   : 'center',
        zIndex: 10000,
        background: 'rgba(77, 0, 10, 0.2)',
    },
    content : {
        position    : 'null', // to override default styles
        top         : 'null',
        left        : 'null',
        right       : 'null',
        bottom      : 'null',
        background  : '#fff',
        padding: 0,
        width: 380,
        border: 'none',
        marginBottom: 152,
        // height      : '75vh'
    }
};
function ShowWalletDetailsModal({wallet, onRequestClose}) {
    const [privateKeyCopied, setPrivateKeyCopied] = useState(false);
    const [depositAddressCopied, setDepositAddressCopied] = useState(false);

    const {icon, name, ticker, wif} = AssetDescriptions[wallet.asset];

    return (
        <Modal style={ShowWalletDetailsModalStyle} onRequestClose={onRequestClose} isOpen>
            <Box>
                <Box style={{borderBottom: '1px solid #ddd', padding: 12, alignItems: 'center', flexDirection: 'row', justifyContent: 'center'}}>
                    <Text textStyle="title" style={{opacity: 0.92}}>View {AssetDescriptions[wallet.asset].name} Wallet</Text>
                </Box>
                <Box style={{overflowY: 'auto', flex: 1, padding: 16}}>
                    <Box direction="row" style={{borderBottom: '1px solid #ccc', backgroundColor: 'rgba(128, 128, 128, 0.05)', padding: 8, marginTop: -16, marginLeft: -16, marginRight: -16, marginBottom: 16}}>
                        <Box style={{width: 20}}>
                            <IoInformationCircle color="#777" size={20} />
                        </Box>
                        <Text textStyle="caption" secondary bold style={{marginLeft: 4}}>You don't need to deposit now—you can also deposit after creating the card.</Text>
                    </Box>
                    <Box direction="row" style={{justifyContent: 'center'}}>
                        <Box style={{alignItems: 'center'}}>
                            <QRCode fgColor={Theme.colorPrimary} value={wallet.asset === 'algo' ? makeAlgorandPassphraseQrString(wallet.passphrase) : wallet.privateKey} />
                            {wallet.asset === 'algo' ? (
                                <Text style={{color: Theme.colorPrimary, marginTop: 4}} textStyle="caption" bold secondary>25-word Passphrase</Text>
                            ) : (
                                <Text style={{color: Theme.colorPrimary, marginTop: 4}} textStyle="caption" bold secondary>Private Key{wif ? ' WIF' : ''}</Text>
                            )}
                        </Box>
                        <Box style={{width: 32}} />
                        <Box style={{alignItems: 'center'}}>
                            <QRCode value={wallet.address} />
                            <Text style={{marginTop: 4}} textStyle="caption" bold secondary>Deposit Address</Text>
                        </Box>
                    </Box>
                    <Box style={{marginTop: 16}}>
                        {wallet.asset === 'algo' ? (
                            <Text style={{color: Theme.colorPrimary, marginBottom: 4}} textStyle="caption" bold secondary>25-word Passphrase</Text>
                        ) : (
                            <Text style={{color: Theme.colorPrimary, marginBottom: 4}} textStyle="caption" bold secondary>Private Key{wif ? ' (Wallet Import Format)' : ''}</Text>
                        )}
                        <Box direction="row">
                            <TextInput value={wallet.asset === 'algo' ? wallet.passphrase : wallet.privateKey} style={{borderTopRightRadius: 0, borderBottomRightRadius: 0, flex: 1, height: 18, borderRight: 'none', outline: 'none'}} readOnly onFocus={(evt) => evt.target.select()} />
                            <CopyToClipboard text={wallet.asset === 'algo' ? wallet.passphrase : wallet.privateKey} onCopy={() => setPrivateKeyCopied(true)}>
                                <Button style={{height: 36, marginTop: 0, backgroundColor: Theme.colorAccent, borderTopRightRadius: 4, borderBottomRightRadius: 4}}>
                                    <Box style={{width: 48, alignItems: 'center'}}>
                                        <Text style={{color: 'white'}} bold>{privateKeyCopied ? 'Copied!' : 'Copy'}</Text>
                                    </Box>
                                </Button>
                            </CopyToClipboard>
                        </Box>
                    </Box>
                    <Box style={{marginTop: 8}}>
                        <Text style={{marginBottom: 4}} textStyle="caption" bold secondary>Deposit Address</Text>
                        <Box direction="row">
                            <TextInput value={wallet.address} style={{borderTopRightRadius: 0, borderBottomRightRadius: 0, flex: 1, height: 18, borderRight: 'none', outline: 'none'}} readOnly onFocus={(evt) => evt.target.select()} />
                            <CopyToClipboard text={wallet.address} onCopy={() => setDepositAddressCopied(true)}>
                                <Button style={{height: 36, marginTop: 0, backgroundColor: Theme.colorAccent, borderTopRightRadius: 4, borderBottomRightRadius: 4}}>
                                    <Box style={{width: 48, alignItems: 'center'}}>
                                        <Text style={{color: 'white'}} bold>{depositAddressCopied ? 'Copied!' : 'Copy'}</Text>
                                    </Box>
                                </Button>
                            </CopyToClipboard>
                        </Box>
                    </Box>
                    <Button
                        style={{borderRadius: 4, marginTop: 16, backgroundColor: Theme.colorAccent, paddingLeft: 12, paddingRight: 12}}
                        onClick={onRequestClose}>
                        <Text style={{color: 'white'}} bold>Done</Text>
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

function Wallets({wallets, onWalletAdded, onRemoveWallet}) {
    const [isModalOpen, setModalOpen] = useState(false);
    const [deleteModalOpenFor, setDeleteModalOpenFor] = useState(null);
    const [deleteErc20ModalOpenFor, setDeleteErc20ModalOpenFor] = useState(null);

    const [walletInfoModalOpenFor, setWalletInfoModalOpenFor] = useState(null);

    const isDesktop = useMediaQuery({
        minWidth: 600,
    });

    const handleWalletCreated = (wallet) => {
        onWalletAdded(wallet);
    };

    const handleRemoveWallet = (asset) => {
        onRemoveWallet(asset);
        setDeleteModalOpenFor(null);
        window.gtag && window.gtag('event', 'increment_asset_removed', {
            asset: 1,
        });
    };

    const handleRemoveErc20Wallet = (asset) => {
        onRemoveWallet(asset);
        setDeleteErc20ModalOpenFor(null);
        window.gtag && window.gtag('event', 'increment_asset_removed', {
            asset: 1,
        });
    };

    if (!wallets.length) {
        return (
            <Box style={{alignItems: 'center'}}>
                <Text>You haven't created any wallets yet.</Text>
                <Button onClick={() => setModalOpen(true)} style={{borderRadius: 4, paddingLeft: 8, paddingRight: 12, marginTop: 12, backgroundColor: Theme.colorAccent}}>
                    <Box direction="row" style={{alignItems: 'center'}}>
                        <IoMdAdd color="white" size={20}/>
                        <Text style={{color: 'white', marginLeft: 4}} bold>Create wallet</Text>
                    </Box>
                </Button>
                {isModalOpen && <CreateWallet existingWallets={wallets} isOpen={true} onRequestClose={() => setModalOpen(false)} onCreateWallet={handleWalletCreated} />}
            </Box>
        );
    }

    return (
        <Box>
            <Box direction="row" style={{border: '1px solid #ffcc00', backgroundColor: 'rgba(255, 204, 0, 0.2)', borderRadius: 4, padding: 8, marginTop: -8, marginBottom: 16}}>
                <Box style={{width: 20}}>
                    <IoWarning color="#ffcc00" size={20} />
                </Box>
                <Text textStyle="caption" secondary bold style={{marginLeft: 4}}>Cryptomas doesn't save your keys. If you leave this page, Cryptomas won't remember your generated wallets, so make sure you record all wallet keys and addresses before depositing.</Text>
            </Box>
            <Text textStyle="title">Wallets</Text>
            <Text textStyle="caption" secondary>Deposit cryptocurrency by sending it to the corresponding wallet's public address. Send ERC-20 tokens to the Ethereum address.</Text>
            {walletsListToStructured(wallets).map((wallet, index) => (
                <Box key={index} direction="row" style={mergeStyles({paddingTop: 16, paddingBottom: 16, alignItems: 'center', borderBottom: '1px solid #eee'}, wallet.tokens && {alignItems: 'flex-start'})}>
                    <img src={AssetDescriptions[wallet.asset].icon} style={{width: 32, marginRight: 16}} />
                    <Box style={wallet.tokens && {marginTop: 5}}>
                        <Text textStyle="subhead">{AssetDescriptions[wallet.asset].name}</Text>
                        {(wallet.tokens && wallet.tokens.map((token, index) => (
                            <Box key={index} direction="row" style={{marginLeft: 8, alignItems: 'center', marginTop: 4}}>
                                <Box style={{marginRight: 4}}>
                                    <Box style={{width: 8, height: 8, borderLeft: '1px solid #ccc', borderBottom: '1px solid #ccc', borderBottomLeftRadius: 2}} />
                                    <Box style={{height: 4}} />
                                </Box>
                                <Box style={{backgroundColor: '#c99d66', borderRadius: 999, paddingLeft: 8, paddingRight: 8, paddingBottom: 1, paddingTop: 1, marginRight: 8}}>
                                    <Text style={mergeStyles({color: 'white', opacity: 0.92}, !isDesktop && {fontSize: 8})} textStyle="caption" bold>ERC-20</Text>
                                </Box>
                                <Text textStyle="caption">{token.erc20.tokenName}</Text>
                                <Box style={{cursor: 'pointer', marginLeft: 4, marginTop: 2, justifyContent: 'center'}} onClick={() => handleRemoveErc20Wallet('erc20:' + token.erc20.tokenAddress)}>
                                    <IoIosClose color="#555" size={20} />
                                </Box>
                            </Box>
                        )))}
                    </Box>
                    <Box style={{flex: 1}} />
                    <Box direction="row" style={{alignItems: 'center'}}>
                        <Button style={{marginRight: 12, borderRadius: 4, border: '1px solid #ccc'}} onClick={() => setWalletInfoModalOpenFor(wallet)}>
                            <Text style={{opacity: 0.92}} bold>Details</Text>
                        </Button>
                        <Box style={{cursor: 'pointer'}} onClick={() => setDeleteModalOpenFor(wallet.asset)}>
                            <IoMdTrash color="#555" size={20} />
                        </Box>
                    </Box>
                </Box>
            ))}
            <Box direction="row">
                <Box onClick={() => setModalOpen(true)} direction="row" style={{paddingTop: 16, paddingBottom: 16, alignItems: 'center', marginBottom: -24, cursor: 'pointer'}}>
                    <Box style={{width: 16, marginRight: 4}}>
                        <IoMdAdd size={16} color={Theme.colorAccent} />
                    </Box>
                    <Text style={{color: Theme.colorAccent}} bold>Add a wallet</Text>
                </Box>
            </Box>
            {isModalOpen && <CreateWallet existingWallets={wallets} isOpen={isModalOpen} onRequestClose={() => setModalOpen(false)} onCreateWallet={handleWalletCreated} />}
            {deleteModalOpenFor && <ConfirmDeleteModal asset={deleteModalOpenFor} onRemoveWallet={handleRemoveWallet} onRequestClose={() => setDeleteModalOpenFor(null)} />}
            {deleteErc20ModalOpenFor && <ConfirmDeleteERC20Modal erc20={deleteErc20ModalOpenFor} onRemoveWallet={handleRemoveErc20Wallet} onRequestClose={() => setDeleteErc20ModalOpenFor(null)} />}
            {walletInfoModalOpenFor && <ShowWalletDetailsModal wallet={walletInfoModalOpenFor} onRequestClose={() => setWalletInfoModalOpenFor(null)}/>}
        </Box>
    );
}

export default function DepositStep({wallets, onWalletAdded, onRemoveWallet, showDisclaimer, onDisclaimerAgree}) {
    return showDisclaimer ? (
        <Disclaimer onContinue={onDisclaimerAgree} />
    ) : (
        <Wallets wallets={wallets} onWalletAdded={onWalletAdded} onRemoveWallet={onRemoveWallet} />
    );
}
