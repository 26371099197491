import Box from '@/lib/components/Box';
import Text from '@/lib/components/Text';
import Constants from '@/lib/constants';

import mergeStyles from '@/lib/styling/mergeStyles';
import nl2br from '@/lib/display/nl2br';

export default function Header({width, backgroundColor, foregroundColor, textColor, emoji, to, message}) {
    const fontSizeTitle = width * 0.08;
    const fontSizeSubhead = fontSizeTitle * 0.4;
    const fontSizeCaption = fontSizeTitle * 0.3;

    const unit = 0.01 * width;

    const paddingTop = 4 * unit;
    const paddingBottom = 3 * unit;
    const paddingLeft = 8 * unit;
    const paddingRight = paddingLeft;

    return (
        <Box style={mergeStyles({boxSizing: 'border-box', width, minHeight: width * 9/16, backgroundColor, paddingTop, paddingRight, paddingBottom, paddingLeft})}>
            <Box style={mergeStyles({alignItems: 'center'}, !(to || message) && {flex: 1})}>
                {!(to || message) && <Box style={{flex: 1 }} />}
                <Box direction="row" style={{alignItems: 'center'}}>
                    <span style={{fontSize: fontSizeTitle * 1.2}}>🎄</span>
                    <Text bold style={{color: foregroundColor, fontSize: fontSizeTitle, marginLeft: unit * 0.5}}>{Constants.APP_NAME}</Text>
                </Box>
                <Text style={{fontSize: fontSizeSubhead, color: foregroundColor}} secondary bold>Crypto Gift Card</Text>
                {!(to || message) && <Box style={{flex: 1 }} />}
                {!(to || message) && (
                    <Text bold secondary style={{color: foregroundColor, fontSize: fontSizeCaption, marginTop: 4 * unit}}>{Constants.WEBSITE_URL}</Text>
                )}
            </Box>
            {(to || message) && (
                <Box style={{flex: 1, alignItems: 'center', marginTop: (to && message) ? 6 * unit : 8 * unit}}>
                    {to && (
                        <Box direction="row" style={{alignItems: 'baseline'}}>
                            <Text bold secondary style={{color: foregroundColor, fontSize: fontSizeSubhead}}>To:</Text>
                            <Text bold style={{color: foregroundColor, fontSize: fontSizeSubhead, marginLeft: unit}}>{to}</Text>
                        </Box>
                    )}
                    {message && (
                        <Text style={mergeStyles({color: textColor, opacity: 0.92, fontSize: fontSizeSubhead}, to && {marginTop: unit})}>{nl2br(message)}</Text>
                    )}
                    <Box style={{flex: 1}} />
                    <Text bold secondary style={{color: foregroundColor, fontSize: fontSizeCaption, marginTop: 3 * unit}}>{Constants.WEBSITE_URL}</Text>
                </Box>
            )}
        </Box>
    );
}
