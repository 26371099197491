import mergeStyles from '@/lib/styling/mergeStyles';

const Styles = {
    chungus: {
        fontSize: 40,
    },
    headline: {
        fontSize: 24,
        fontWeight: 400,
    },
    title: {
        fontSize: 20,
        fontWeight: 600,
    },
    subhead: {
        fontSize: 16,
        fontWeight: 400,
    },
    body: {
        fontSize: 14,
        fontWeight: 400,
    },
    caption: {
        fontSize: 12,
    },
};

export default function Text({textStyle, style, bold, secondary, code, ...restProps}) {
    return (
        <span style={mergeStyles(
            Styles[textStyle || 'body'],
            style,
            bold && {fontWeight: 'bold'},
            secondary && {opacity: 0.7},
            code && {fontFamily: 'Menlo, Monaco, Consolas, \'Courier New\', monospace'}
            )
        } {...restProps} />
    );
}
